<template>
    <BaseButton
        class="toggle-button"
        type="button"
        :data-active="isActive"
        @click="onClick"
    >
        <slot
            v-if="isActive"
            name="label-active"
        />
        <slot
            v-if="!isActive"
            name="label-inactive"
        />

        <slot />

        <template #after>
            <slot
                v-if="!isActive"
                name="icon-inactive"
            >
                <BaseIcon icon="plus" />
            </slot>

            <slot
                v-if="isActive"
                name="icon-active"
            >
                <BaseIcon icon="check" />
            </slot>
        </template>
    </BaseButton>
</template>

<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        value?: number[] | null;
        toggleValue: number;
    }>(),
    {
        value: null,
    }
);

const emit = defineEmits(['input', 'toggle']);

const isActive = computed(() => {
    if (props.value) {
        return props.value.includes(props.toggleValue) ? true : false;
    }

    return false;
});

watch(isActive, () => {
    emit('toggle', isActive.value);
});

const onClick = async() => {
    const newArray = props.value ? [...props.value] : [];

    // If currently active, remove from array
    if (isActive.value) {
        const index = newArray.indexOf(props.toggleValue);

        newArray.splice(index, 1);
    // If not active, add to array
    } else {
        newArray.push(props.toggleValue);
    }

    emit('input', newArray);
};
</script>


<style src="./ToggleButton.less" lang="less"></style>
